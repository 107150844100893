.con[data-v-2480a5bb] {
  width: 100%;
  height: 100%;
  padding: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.header[data-v-2480a5bb] {
  height: 44px;
  line-height: 44px;
  width: 100%;
  letter-spacing: 2px;
  border-bottom: 1px solid #cacaca;
  text-indent: 10px;
  font-size: 20px;
  font-weight: 550;
  color: #0070c1;
  position: relative;
}
.header .header-more[data-v-2480a5bb] {
    cursor: pointer;
    color: #aaa;
    position: absolute;
    right: 10px;
    top: 0;
}
.header .header-more[data-v-2480a5bb]:hover {
      color: #409eff;
}
.header .header-more span[data-v-2480a5bb] {
      font-size: 14px;
}
.header .header-more span i[data-v-2480a5bb] {
        margin-left: -12px;
}
[data-v-2480a5bb] .el-tabs {
  padding: 0 5px;
}
[data-v-2480a5bb] .el-card__body {
  padding: 0;
}
.form-container[data-v-2480a5bb] {
  background-color: #fff;
  border-color: transparent;
  border-style: solid;
  border: 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.form-container .form-content[data-v-2480a5bb] {
    width: 1880px;
    background: #fff;
    border-color: inherit;
    color: inherit;
    margin: 0 auto;
}
.form-container .form-content .form-viewer-item[data-v-2480a5bb] {
      position: relative;
      width: 100%;
      min-height: 50px;
      line-height: 50px;
      margin-bottom: 0px;
      background-color: #fff;
      padding: 5px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      box-sizing: border-box;
}
.form-container .form-content .form-viewer-item[data-v-2480a5bb] .el-form-item__label {
        color: inherit;
}
.form-container .form-content .form-viewer-item[data-v-2480a5bb] .el-select {
        width: 100%;
}
.form-container .form-content .form-viewer-item[data-v-2480a5bb] .el-input-number {
        width: 100%;
}
.form-container .form-content .form-viewer-item[data-v-2480a5bb] .el-date-editor {
        width: 100%;
}
.form-container .form-content > .form-viewer-item[data-v-2480a5bb] {
      border-color: inherit;
      border-style: solid;
      border-top-width: 0px;
      border-left-width: 0px;
      border-right-width: 0px;
      border-bottom-width: 0px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.form-container .form-content > .form-viewer-item[data-v-2480a5bb]:last-of-type {
        border-bottom-width: 1px;
}
.form-container .form-content .form-viewer-item-textarea[data-v-2480a5bb] {
      height: auto;
}
.form-container .form-content .form-viewer-item-text[data-v-2480a5bb] {
      padding: 0px;
      min-height: 0px;
}
.form-container .form-content .form-viewer-item-text[data-v-2480a5bb] .el-form-item__content {
        line-height: 1;
}
.form-container .form-content .form-viewer-item-checkbox[data-v-2480a5bb] {
      height: auto;
}
.form-container .form-content .form-viewer-item-card[data-v-2480a5bb] {
      width: 100%;
      background-color: #fff;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      margin-top: 10px;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-color: inherit;
      border-style: solid;
      border-top-width: 0px;
      border-left-width: 0px;
      border-bottom-width: 0px;
      border-right-width: 0px;
}
.form-container .form-content .form-viewer-item-card[data-v-2480a5bb]:first-of-type {
        margin-top: 0px;
}
.form-container .form-content .form-viewer-item-card[data-v-2480a5bb]:last-child {
        border-bottom-width: 1px;
}
.form-container .form-content .form-viewer-item-card .form-viewer-item[data-v-2480a5bb] {
        border-left-width: 0px;
        border-right-width: 1px;
        border-bottom-width: 0px;
        border-color: inherit;
        border-style: solid;
}
.form-container .form-content .form-viewer-item-card .form-viewer-item[data-v-2480a5bb]:last-of-type {
          border-bottom-width: 0px;
}
.form-container .form-content .form-viewer-item-card .form-viewer-item-grid:first-of-type .form-viewer-item-grid-col[data-v-2480a5bb] {
        border-top-width: 0px;
}
.form-container .form-content .form-viewer-item-card .form-viewer-item-grid[data-v-2480a5bb]:last-of-type {
        border-bottom-width: 0px;
}
.form-container .form-content .form-viewer-item-grid[data-v-2480a5bb] {
      width: 100%;
      min-height: 50px;
      border-color: inherit;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 0px;
      background-color: transparent;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border-color: inherit;
      border-style: solid;
      border-top-width: 0px;
      border-left-width: 0px;
      border-bottom-width: 0px;
      border-right-width: 0px;
      box-sizing: border-box;
}
.form-container .form-content .form-viewer-item-grid .form-viewer-item-grid-col[data-v-2480a5bb] {
        position: relative;
        border-color: inherit;
        border-style: solid;
        border-top-width: 1px;
        border-left-width: 0px;
        border-bottom-width: 0px;
        border-right-width: 1px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
}
.form-container .form-content .form-viewer-item-grid .form-viewer-item-grid-col .form-viewer-item[data-v-2480a5bb] {
          border-style: solid;
          border-width: 0px;
          border-color: inherit;
}
.form-container .form-content .form-viewer-item-grid .form-viewer-item-grid-col .form-grid-col-label-border[data-v-2480a5bb] {
          position: absolute;
          height: 100%;
          top: 0px;
          left: 200px;
          border-top-width: 0px;
          border-left-width: 0px;
          border-right-width: 1px;
          border-bottom-width: 0px;
          border-color: inherit;
          border-style: solid;
}
.form-container .form-content .form-viewer-item-grid[data-v-2480a5bb]:last-of-type {
        border-bottom-width: 1px;
}
.form-container .form-content .form-viewer-item-table-wrapper[data-v-2480a5bb] {
      width: 100%;
      min-height: 100px;
      margin-bottom: 0px;
      background-color: #fff;
      padding: 10px 14px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border-color: inherit;
      border-style: solid;
      border-top-width: 1px;
      border-left-width: 1px;
      border-right-width: 1px;
      border-bottom-width: 0px;
}
.form-container .form-content .form-viewer-item-table-wrapper .form-viewer-item-table-tools[data-v-2480a5bb] {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
}
.form-container .form-content .form-viewer-item-table-wrapper .form-viewer-item-table-tools .form-viewer-item-table-tips[data-v-2480a5bb] {
          color: red;
          margin-left: 14px;
}
.form-container .form-content .form-viewer-item-table-wrapper .form-viewer-item-table[data-v-2480a5bb] .el-form-item {
        min-height: auto;
        line-height: 1;
        padding: 0px;
}
.form-container .form-content .form-viewer-item-table-wrapper .form-viewer-item-table[data-v-2480a5bb] .el-form-item .el-form-item__label {
          display: none;
}
.form-container .form-content .form-viewer-item-table-wrapper .form-viewer-item-table[data-v-2480a5bb] .el-form-item .el-form-item__content {
          margin-left: 0 !important;
}
.form-container .form-content .form-viewer-item-table-wrapper .form-viewer-item-table[data-v-2480a5bb] .el-form-item .el-form-item__content .el-form-item__error {
            color: #f56c6c;
            font-size: var(--rootFontSize4);
            line-height: 1;
            position: absolute;
            top: 35%;
            left: auto;
            right: 10px;
            z-index: 999;
            padding-top: 0px;
}
.form-container .form-content[data-v-2480a5bb] .ele-input .el-input__inner {
      color: inherit;
}
.form-container .form-content .form-viewer-item-border[data-v-2480a5bb] {
      position: relative;
      border-left: 1px solid;
      border-top: 1px solid;
      border-right: 1px solid;
}
.form-container .form-content .form-viewer-item-label-border[data-v-2480a5bb] {
      position: relative;
      border-left: 1px solid;
      border-top: 1px solid;
      border-right: 1px solid;
}
.form-container .form-content .form-viewer-item-label-border[data-v-2480a5bb] .el-form-item__content {
        position: relative;
}
.form-container .form-content .form-viewer-item-label-border[data-v-2480a5bb] .el-form-item__content::before {
          content: "";
          position: absolute;
          display: block;
          height: calc(100% + 13px);
          left: -2px;
          top: 0px;
          border-top-width: 0px;
          border-left-width: 0px;
          border-right-width: 1px;
          border-bottom-width: 0px;
          border-color: inherit;
          border-style: solid;
          margin-top: -5px;
}
.form-container .form-content .form-viewer-item-label-left[data-v-2480a5bb] .el-form-item__label {
      text-align: left;
}
.form-container .form-content .form-viewer-item-label-right[data-v-2480a5bb] .el-form-item__label {
      text-align: right;
}
.form-container .form-content .form-viewer-item-label-center[data-v-2480a5bb] .el-form-item__label {
      text-align: center;
}
